<template>
  <v-row>
    <v-col cols="12" md="5">
      <v-card class="px-4">
        <v-row class="justify-center text-center" no-gutters>
          <v-col cols="12" class="justify-center py-4">
            <v-hover v-slot:default="{}">
              <v-list-item-avatar class="mx-auto" color="grey" size="150">
                <croppa
                  v-model="croppa"
                  ref="croppa"
                  :width="150"
                  :height="150"
                  :placeholder="$t('form_candidate_upload')"
                  :placeholder-font-size="12"
                  :initial-image="
                    entity.logo
                      ? `${apiUrl}/images/entity/${entity.logo}`
                      : `/logo_default.png`
                  "
                >
                  <!-- <img slot="placeholder" src="/logo_default.png" /> -->

                  <!-- <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-ease-in-ease-out orange darken-2 v-card--reveal display-3 white--text"
                      style="height: 100%;"
                    >
                      <v-btn
                        text
                        outlined
                        color="white"
                        class
                        style="opacity: 1;"
                        fab
                        large
                        top
                        @click="$refs.croppa.chooseFile()"
                      >
                        <div class="text-center">
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn fab class="mx-2" color="grey" v-on="on">
                                <v-icon>mdi-camera-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("form_candidate_upload") }}</span>
                          </v-tooltip>
                        </div>
                      </v-btn>
                    </div>
                  </v-expand-transition> -->
                </croppa>
              </v-list-item-avatar>
            </v-hover>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  style="margin-top: 15%; margin-left: -5%"
                  icon
                  small
                  @click="croppa.chooseFile()"
                  ><v-icon>mdi-camera-plus</v-icon></v-btn
                >
              </template>
              <div v-html="$t('form_candidate_upload')"></div>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="entity.name"
              :label="$t('Institution_name')"
              outlined
              name="name"
              type="text"
              dense
              required
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete
              v-model="entity.areasExpertise"
              :items="areasExpertise($i18n.locale)"
              item-text="text"
              item-value="key"
              small-chips
              :label="$t('Areasofaction')"
              multiple
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="entity.about"
              outlined
              :label="$t('aboutCompany')"
              rows="6"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <v-form ref="form" lazy-validation>
        <v-card class="mb-5 px-5">
          <v-card-title>{{ $t("Information") }}</v-card-title>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="entity.email"
                label="E-mail"
                outlined
                name="email"
                type="email"
                dense
                :rules="emailRules"
                required
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.telephone"
                :label="$t('form_user_phone')"
                outlined
                type="tel"
                dense
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.telephone2"
                :label="$t('form_user_phone') + '2'"
                outlined
                type="tel"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.nuit"
                label="Nuit"
                outlined
                type="text"
                dense
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.license"
                :label="$t('LicenseNr')"
                outlined
                type="text"
                dense
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="entity.address"
                :label="$t('address')"
                outlined
                type="text"
                dense
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.media.facebook"
                label="Facebook"
                outlined
                type="text"
                dense
                hide-details
                prepend-inner-icon="mdi-facebook"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.media.linkedin"
                label="Linkedin"
                outlined
                type="text"
                dense
                hide-details
                prepend-inner-icon="mdi-linkedin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.media.twitter"
                label="Twitter"
                outlined
                type="text"
                dense
                hide-details
                prepend-inner-icon="mdi-twitter"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="entity.media.website"
                label="Website"
                outlined
                type="text"
                dense
                hide-details
                prepend-inner-icon="mdi-web"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            class="d-flex flex-row justify-end align-center mt-5 px-6 py-8"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              dense
              @click="back"
              color="primary"
              medium
              >{{ $t("cancel") }}
            </v-btn>
            <v-btn
              :disabled="isLoading"
              dense
              color="primary"
              medium
              @click.stop="handleSubmit"
              >{{ $t("save") }}
            </v-btn>
          </v-row>
        </v-card>
      </v-form>
    </v-col>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-row>
</template>

<script>
import { API_URL } from "@/api";
import { formatError } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import { UPDATE_ENTITY_MUTATION } from "./../graphql/Mutation";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import Entity from "@/models/Entity";
import SuccessDialog from "../../../components/SuccessDialog.vue";
export default {
  name: "EditProfile",
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  data: () => ({
    apiUrl: API_URL,
    croppa: {},
    emailRules: [
      (v) => !!v || "E-mail must be valid",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    entity: new Entity(),
    error: undefined,
    isLoading: false,
    showError: false,
    showSuccess: false,
    success: "undefined",
  }),
  created() {
    this.entity.media = {
      facebook: "",
      linkedin: "",
      twitter: "",
      website: "",
    };
    this.entity = this.currentEntity;
  },
  computed: {
    ...mapGetters({
      areasExpertise: "library/areasExpertise",
      currentEntity: "entity/getCurrentEntity",
    }),
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },
  methods: {
    ...mapActions({
      updateEntityState: "auth/updateEntity",
      setEntityState: "entity/setEntity",
    }),
    closeErrorDialog(value) {
      this.showError = value;
    },

    back() {
     this.$emit("switch");
    },
    async handleSubmit() {
      this.$refs.form.validate();

      if (
        this.entity.address != "" &&
        this.entity.name != "" &&
        this.entity.telephone != "" &&
        this.entity.nuit != "" &&
        this.entity.license != ""
      ) {
        this.isLoading = true;
        try {
          this.entity.newLogo = this.croppa.generateDataUrl("image/jpeg", 0.8);
          await this.$apollo
            .mutate({
              mutation: UPDATE_ENTITY_MUTATION,
              variables: {
                entityInput: this.entity,
              },
            })
            .then(({ data }) => {
              this.updateEntityState(data.updateEntity);
              this.setEntityState(data.updateEntity);
            });
          // this.$emit('switch')
          this.success = this.$t("profileSuccess");
          this.showSuccess = true;
        } catch (error) {
          console.log(error);
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
</style>
