<template>
  <v-row>
    <v-col cols="12" md="5">
      <v-hover v-slot:default="{ hover }">
      
        <v-card :class="{ 'on-hover': hover }">
          <v-list-item class="pt-5">
            <v-list-item-avatar
              class="mx-auto"
              color="grey"
              size="100"
            >
              <img
                :src="
                    entity.logo
                      ? `${apiUrl}/images/entity/${entity.logo}`
                      : `/logo_default.png`
                  "
                alt
              />
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-medium pb-2 text-center">
                {{ entity.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="subtitle-2 text-center">
                {{ entity.address }}
              </v-list-item-subtitle> 
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
             <div class="subtitle-2 text-center text--primary mb-8" style="color:#000">
              {{ entity.about }}
            </div>
            <!--<pre>
              {{entity.media}}
            </pre>-->
            <v-row
              class="align-center justify-center mb-3"
            >
              <v-btn
                :href="`//${entity.media.facebook}`"
                target="_blank"
                icon
              >
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <div class="mx-2"></div>
              <v-btn
                :href="`//${entity.media.linkedin}`"
                target="_blank"
                icon
              >
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <div class="mx-2"></div>
              <v-btn
                :href="`//${entity.media.twitter}`"
                target="_blank"
                icon
              >
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <div class="mx-2"></div>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row
              class="align-center justify-center mb-3"
            >
              <v-btn
                :href="`//${entity.media.website}`"
                target="_blank"
                tile
                color="primary"
                dark
              >Website</v-btn>
              <v-btn
                tile
                outlined
                color="primary"
                dark
              >Portfólio</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-hover>

      <v-hover v-slot:default="{ hover }">
        <v-card
          class="mt-5"
          :class="{ 'on-hover': hover }"
        >
          <v-card-text>
            <h2 class="title mb-2">{{$t('Areasofaction')}}</h2>

            <v-chip-group
              column
              multiple
              v-for="area in entity.areasExpertise"
              :key="area"
            >
              <v-chip
                filter
                outlined
                class="my-0"
              >{{ $t(area) }}</v-chip>
            </v-chip-group>
          </v-card-text>

          <v-card-text></v-card-text>
        </v-card>
      </v-hover>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="mb-5">
        <v-card-title>Status</v-card-title>
        <v-divider class="mx-5"></v-divider>
        <v-list-item>
          <p class="caption">{{$t('Publications')}}</p>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            x-small
            class="caption"
          >{{ getTotalVacancy.total }}</v-btn>
        </v-list-item>
        <v-list-item>
          <p class="caption">{{$t('Vacancyviews')}}</p>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            x-small
            class="caption"
          >
            {{ getTotalVacancy.totalViews }}
          </v-btn>
        </v-list-item>
      </v-card>

      <v-hover v-slot:default="{ hover }">
        <v-card
          class="mb-5"
          :class="{ 'on-hover': hover }"
        >
          <v-card-title>{{$t('Information')}}</v-card-title>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption">Email</v-list-item-subtitle>
              <v-list-item-title class="subtitle-2 font-weight-regular">
                {{ entity.email }} 
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mx-5"></v-divider>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption">{{$t('Contacts')}}</v-list-item-subtitle>
              <v-list-item-title class="subtitle-2 font-weight-regular">
                {{ entity.telephone }}, {{ entity.telephone2 }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mx-5"></v-divider>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption">{{$t('form_client_adress')}}</v-list-item-subtitle>
              <v-list-item-title class="subtitle-2 font-weight-regular">
                {{ entity.address }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="subheading pt-4"></div>
          <v-img
            src="/map.jpg"
            aspect-ratio="1.7"
            contain
          ></v-img>
          <div class="subheading pt-4"></div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { API_URL } from '@/api'
import { GET_TOTAL_VACANCY_QUERY } from './../graphql/Query'
import { mapGetters } from 'vuex'
export default {
  name: 'ProfileDetails',
  data: () => ({
    apiUrl: API_URL,
    getTotalVacancy: {}
  }),
  apollo: {
    getTotalVacancy: {
      query: GET_TOTAL_VACANCY_QUERY,
    }
  },
  computed: {
    ...mapGetters({
      entity: 'entity/getCurrentEntity'
    })
  },
}
</script>