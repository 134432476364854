import gql from 'graphql-tag'
import entityFragment from './EntityFragment'
import roleFragment from './RoleFragment'
import userFragment from './../../../../auth/graphql/userFragment'

export const UPDATE_ENTITY_MUTATION = gql`
  mutation UpdateEntity($entityInput: EntityInput) {
    updateEntity(entityInput: $entityInput) {
      ...${entityFragment}
    }
  }
`

export const CREATE_ROLE_MUTATION = gql`
  mutation CreateRole($roleInput: RoleInput) {
    createRole(roleInput: $roleInput) {
      ...${roleFragment}
    }
  }
`

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation CreateEmployee($userInput: UserInput) {
    createEmployee(userInput: $userInput) {
      ...${userFragment}
    }
  }
`

export const DELETE_ROLE_MUTATION = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`

export const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($id: ID!, $roleInput: RoleInput) {
    updateRole(id: $id, roleInput: $roleInput) {
      ...${roleFragment}
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`