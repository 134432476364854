var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover }},[_c('v-list-item',{staticClass:"pt-5"},[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"color":"grey","size":"100"}},[_c('img',{attrs:{"src":_vm.entity.logo
                    ? (_vm.apiUrl + "/images/entity/" + (_vm.entity.logo))
                    : "/logo_default.png","alt":""}})])],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium pb-2 text-center"},[_vm._v(" "+_vm._s(_vm.entity.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"subtitle-2 text-center"},[_vm._v(" "+_vm._s(_vm.entity.address)+" ")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"subtitle-2 text-center text--primary mb-8",staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(_vm.entity.about)+" ")]),_c('v-row',{staticClass:"align-center justify-center mb-3"},[_c('v-btn',{attrs:{"href":("//" + (_vm.entity.media.facebook)),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-facebook")])],1),_c('div',{staticClass:"mx-2"}),_c('v-btn',{attrs:{"href":("//" + (_vm.entity.media.linkedin)),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1),_c('div',{staticClass:"mx-2"}),_c('v-btn',{attrs:{"href":("//" + (_vm.entity.media.twitter)),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("mdi-twitter")])],1),_c('div',{staticClass:"mx-2"})],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"align-center justify-center mb-3"},[_c('v-btn',{attrs:{"href":("//" + (_vm.entity.media.website)),"target":"_blank","tile":"","color":"primary","dark":""}},[_vm._v("Website")]),_c('v-btn',{attrs:{"tile":"","outlined":"","color":"primary","dark":""}},[_vm._v("Portfólio")])],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-5",class:{ 'on-hover': hover }},[_c('v-card-text',[_c('h2',{staticClass:"title mb-2"},[_vm._v(_vm._s(_vm.$t('Areasofaction')))]),_vm._l((_vm.entity.areasExpertise),function(area){return _c('v-chip-group',{key:area,attrs:{"column":"","multiple":""}},[_c('v-chip',{staticClass:"my-0",attrs:{"filter":"","outlined":""}},[_vm._v(_vm._s(_vm.$t(area)))])],1)})],2),_c('v-card-text')],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',[_vm._v("Status")]),_c('v-divider',{staticClass:"mx-5"}),_c('v-list-item',[_c('p',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('Publications')))]),_c('v-spacer'),_c('v-btn',{staticClass:"caption",attrs:{"outlined":"","x-small":""}},[_vm._v(_vm._s(_vm.getTotalVacancy.total))])],1),_c('v-list-item',[_c('p',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('Vacancyviews')))]),_c('v-spacer'),_c('v-btn',{staticClass:"caption",attrs:{"outlined":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.getTotalVacancy.totalViews)+" ")])],1)],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-5",class:{ 'on-hover': hover }},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Information')))]),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v("Email")]),_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.entity.email)+" ")])],1)],1),_c('v-divider',{staticClass:"mx-5"}),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('Contacts')))]),_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.entity.telephone)+", "+_vm._s(_vm.entity.telephone2)+" ")])],1)],1),_c('v-divider',{staticClass:"mx-5"}),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('form_client_adress')))]),_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.entity.address)+" ")])],1)],1),_c('div',{staticClass:"subheading pt-4"}),_c('v-img',{attrs:{"src":"/map.jpg","aspect-ratio":"1.7","contain":""}}),_c('div',{staticClass:"subheading pt-4"})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }